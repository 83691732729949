<template>
  <div class="border border-gray-400 rounded-3xl px-6 pt-4 pb-6">
    <div class="flex flex-row justify-between">
      <div class="flex items-center">
        <div class="text-primary font-bold text-xl">Videos</div>
        <div class="flex items-center bg-gray-100 rounded-lg w-36 sm:w-64 px-2 py-1 ml-8">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          <input v-model="search" class="bg-transparent focus:outline-none w-full ml-2" placeholder="Search video" />
        </div>
      </div>
      <div class="flex flex-row items-center gap-2">
        <font-awesome-icon icon="fa-solid fa-filter" class="sm:hidden block ml-8" />
        <div class="font-bold text-sm hidden sm:block">Filter by group</div>
        <div class="flex">
          <select id="select-kete"
            class="border border-gray-300 text-gray-900 text-sm rounded-xl block w-full px-2.5 py-0.5" v-model="category"
            @change="loadData">
            <option value="">All</option>
            <option value="water">Seadoo</option>
            <option value="canam">Canam</option>
            <!-- <option value="sky">Sky Adveture</option> -->
          </select>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8" v-if="filteredVideos.length > 0">
      <div v-for="video in filteredVideos" :key="video.id" @click="openDetailPage(video)"
        class="rounded-md overflow-hidden cursor-pointer border border-gray-200 shadow-lg hover:shadow-hover">
        <div class="text-center w-full h-36 border border-gray-300 grid items-center" :style="{
          backgroundImage: 'url(\'' + video.link_foto + '\')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }">
          <div class="text-3xl text-white">
            <font-awesome-icon icon="fa-regular fa-circle-play" />
          </div>
        </div>
        <div class="font-bold text-center text-sm p-2">{{ video.judul }}</div>
      </div>
    </div>
    <div class="grid mt-8" v-else>
      <div class="text-xl font-bold text-center">Video not found...</div>
    </div>
  </div>
</template>

<script>
import { Slugify } from "@/utils";

export default {
  name: "VideoIndex",

  data() {
    return {
      search: "",
      category: "",
      per_page: 20,
      videos: [],
    };
  },

  computed: {
    filteredVideos() {
      return this.videos.filter(
        (val) =>
          val.judul.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  async created() {

    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Video", routeName: "VideoIndex" },
    ]);
    this.loadData();
  },

  methods: {
    openDetailPage(video) {
      this.$router.push({
        name: "VideoDetail",
        params: { slug: Slugify(video.judul), video },
      });
    },
    async loadData() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.get(
          `${this.$apiTripweWeb}/video/list`, {
          params: {
            category: this.category,
            per_page: this.per_page
          }
        }
        );

        this.videos = response.data.data.item ?? [];
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    }
  },
};
</script>
